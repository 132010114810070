import React, { FC, Fragment } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import { parseBoolean } from 'utils/parseHelpers';
import { ICardRelatedArticleProps } from './model';
import './CardRelatedArticle.scss';

const CardRelatedArticle: FC<ICardRelatedArticleProps> = ({
  articleData: { shortTitle, shortText, link = '/', alt, text, label, title, tags = [], image, button },
}) => {
  const category = tags?.find((tag) => parseBoolean(tag.articleCategory));
  const cardText = shortText || text;
  const cta = button?.length ? button[0] : null;
  const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

  return (
    <div className="card-related-article">

      <ConditionalWrapper
        condition={!cta}
        wrapper={children => <Link to={link}>{children}</Link>}
      >
        <Fragment>
          {label ? <span className="card-related-article__label">{label}</span> : null}
          {image?.fallbackUrl ? (
            <GatsbyImage className="card-related-article__img" fluid={image} alt={alt} />
          ) : null}
          <div className="card-related-article__body">
            {category?.link?.[0].name ? (
              <p className="card-related-article__category">{category.link[0].name}</p>
            ) : null}
            {title || shortTitle ? (
              <DangerouslySetInnerHtml
                className="card-related-article__title"
                element="h2"
                html={shortTitle || title}
              />
            ) : null}
            {text || shortText ? (
              <DangerouslySetInnerHtml
                className="card-related-article__text"
                html={cardText.replace(/<\/?a[^>]*>/g, '')}
              />
            ) : null}

            {cta ? (
              <div className="card-related-article__cta">
                <Button to={cta.url} ariaLabel={cta.name} variant="secondary">
                  {cta.name}
                  <IconCustom icon="chevron-right" />
                </Button>
              </div>
            ) : null}
          </div>
        </Fragment>
      </ConditionalWrapper>
    </div>
  );
};
export default CardRelatedArticle;
