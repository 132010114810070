import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import getSchemaScript from './utils';
import { FaqListProps } from './model';
import './FaqList.scss';

const FaqList: FC<FaqListProps> = ({ title, items }) => (
  <div className="gs-symptom-section__panel-block gs-faq-list">
    {items?.length ? <Helmet script={getSchemaScript(items)} /> : null}
    {title ? <DangerouslySetInnerHtml className="gs-faq__title" element="h2" html={title} /> : null}

    {items?.length ? 
    <>
      {items.map(({ properties: { answer, question, icon } }) => (

        <div className="gs-faq-list__item" key={question}>
            <div className="gs-faq-list__icon">
              <DangerouslySetInnerHtml html={icon} />
            </div>

            <div className="gs-faq-list__content">
              <DangerouslySetInnerHtml className="gs-faq-list__title" element="h3" html={question} />
              <DangerouslySetInnerHtml className="gs-faq-list__answear" html={answer} />
            </div>
        </div>
      ))}
    </> : null}

  </div>
);

export default FaqList;
