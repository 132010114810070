import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import Layout from 'components/Layout';
import ArticleTeaser from 'components/ArticleTeaser';
import PageBreaker from 'components/PageBreaker';
import ArticleBody from 'components/ArticleBody';
import RelatedArticles from 'components/RelatedArticles';
import GoBackButton from 'components/common/GoBackButton';
import SocialLinks from 'components/SocialLinks';
import TextBlock from 'components/common/TextBlock';
import { getSettingsPropsByStructure, parseBoolean } from 'utils/parseHelpers';
import RelatedProducts from 'components/RelatedProducts';
import SymptomSection from 'components/SymptomSection';
import BodyRenderer from 'components/common/BodyRenderer';
import getLocalDate from 'utils/getLocalDate';
import BreadCrumbs from 'components/common/BreadCrumbs';
import { IPropsArticlePage } from './model';
import './ArticlePage.scss';

const additionalBlocks = {
  'Symptom Block': ({ properties }, keyId) => (
    <SymptomSection key={keyId} {...properties} isArticlePage />
  ),
  'Article Disclaimer': ({ properties }, keyId) => (
    <div key={keyId} className="aticle-disclaimer">
      <Container fluid>
        <TextBlock {...properties} />
      </Container>
    </div>
  ),
};

const ArticlePage: FC<IPropsArticlePage> = ({
  location,
  data: {
    page: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          openGraphImageUrl,
          image,
          alt,
          title: articleTitle,
          subtitle,
          text,
          tags,
          body,
          additionalContent,
          date,
          lang,
          hideFeaturedImage,
          hideVariant,
          showBreadcrumbs,
          isFamily,
        },
      ],
    },
    header,
    footer,
    siteSettings,
    commonSettings,
    relatedArticles,
    relatedProducts,
    recommendedProducts: products,
  },
  pageContext: { relatedProductsLinks, breadCrumbs },
}) => {
  const ADPSettings = getSettingsPropsByStructure(commonSettings, 'ADP Related Articles');
  const articleSettings = getSettingsPropsByStructure(commonSettings, 'Article');
  const videoSettings = getSettingsPropsByStructure(commonSettings, 'Video Modal');
  const relatedProductsSettings = getSettingsPropsByStructure(
    commonSettings,
    'ADP Related Products'
  );
  const { title, limit, ariaAllProducts, linkAllProducts } = relatedProductsSettings;

  const alpLink = location.pathname.split('/').filter((rout) => rout)[0];
  const localDate = getLocalDate(date, lang);
  const isFeaturedImageHidden = parseBoolean(hideFeaturedImage);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        imageUrl: image.fallbackUrl,
        imageAlt: alt,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      className="article-page-container"
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <div className="article-details-page">
        <Container fluid>
          {articleSettings ? (
            <>
              <GoBackButton
                text={articleSettings.backBtnText}
                staticUrl={alpLink}
                ariaBack={articleSettings.ariaBack}
              />
              <SocialLinks
                links={articleSettings.socialLinks}
                socialPostTitle={articleSettings.socialPostTitle}
              />
            </>
          ) : null}
          <ArticleTeaser title={articleTitle} subtitle={subtitle} text={text} tags={tags} />
        </Container>
        {!isFeaturedImageHidden ? <PageBreaker img={image} alt={alt} /> : null}
        <Container fluid>
          <ArticleBody
            content={body}
            date={localDate}
            videoSettings={videoSettings}
            dateText={articleSettings?.dateText}
          />
        </Container>
        <BodyRenderer
          bodyData={additionalContent}
          bodyStructure={additionalBlocks}
          bodyItemProps={{ ...{ products } }}
        />
        <RelatedArticles
          limit={ADPSettings?.limit}
          articles={relatedArticles.nodes}
          title={ADPSettings?.title}
        />
        {limit ? (
          <RelatedProducts
            {...{
              limit,
              relatedProducts,
              hideVariant,
              title,
              ariaAllProducts,
              linkAllProducts,
              relatedProductsLinks,
              isFamily,
            }}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ArticleQuery(
    $recommendedProducts: [String]
    $relatedProductsLinks: [String]
    $relatedArticlesLinks: [String]
    $link: String = ""
    $lang: String = ""
    $articleSortInput: UmbracoArticlesSortInput
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          preTitle
          minText
          secText
          closeModalText
          backBtnText
          ariaBack
          dateText
          socialPostTitle
          socialLinks {
            properties {
              ariaLabel
              show
              name
            }
          }
          limit
          ariaLabel
          ariaAllProducts
          linkAllProducts {
            url
            name
          }
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allUmbracoArticles(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        id
        link
        relatedArticlesLinks
        title
        subtitle
        alt
        shortTitle
        text
        date
        lang
        hideFeaturedImage
        hideVariant
        body {
          properties {
            title
            text
            link
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
            grid {
              properties {
                image {
                  ...FragmentGatsbyProps
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 330) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                imageAlt
                title
                description
              }
            }
            videoPreviewPosterAlt
            videoPreviewPoster {
              ...FragmentGatsbyImage
            }
            playButtonAria
          }
          structure
        }
        showBreadcrumbs
        isFamily
        additionalContent {
          ... on TArticleDisclaimerStructure {
            structure
            properties {
              text
            }
          }
          ... on TSymptomBlockStructure {
            properties {
              title
              description
              imageAlt
              mobileImageAlt
              imageAlign
              menuTitle
              anchorName
              showInMenu
              image {
                ...FragmentGatsbyImage
              }
              mobileImage {
                ...FragmentGatsbyProps
                gatsbyImage {
                  ...FragmentMiddleImage
                }
                mobileImage {
                  childImageSharp {
                    fluid(maxHeight: 204) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              panelBlocks {
                properties {
                  list {
                    properties {
                      hideVariant
                      link {
                        url
                      }
                    }
                  }
                  color
                  text
                  title
                  isFamily
                  link
                  videoPreviewPosterAlt
                  videoPreviewPoster {
                    ...FragmentGatsbyImage
                  }
                  playButtonAria
                  isExpanded
                  items {
                    properties {
                      answer
                      question
                    }
                    structure
                  }
                  title
                  linkToShop {
                    url
                    name
                    target
                  }
                  icons {
                    properties {
                      icon
                      label
                    }
                  }
                  ariaLabel
                  image {
                    ...FragmentGatsbyProps
                  }
                  imageAlt
                }
                structure
              }
              color
            }
            structure
          }
        }
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentLargeImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          articleCategory
          id
          name
          link {
            name
          }
        }
      }
    }
    recommendedProducts: allProduct(filter: { link: { in: $recommendedProducts } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyCardImage
        }
        linkFamily {
          url
        }
        imageFamily {
          ...FragmentGatsbyCardImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $relatedProductsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        imageFamily {
          ...FragmentGatsbyImage
        }
        linkFamily {
          url
        }
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(
      filter: { link: { in: $relatedArticlesLinks } }
      sort: $articleSortInput
    ) {
      nodes {
        id
        link
        title
        shortTitle
        label
        text
        shortText
        alt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          articleCategory
          id
          name
        }
      }
    }
  }
`;

export default ArticlePage;
