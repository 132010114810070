import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import ControlledCarousel from 'components/ControlledCarousel';
import Button from 'components/common/Button';
import ProductCard from 'components/ProductСard';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import { sortRelatedProducts } from 'utils/helpers';
import responsiveObj from './constants';

import './RelatedProducts.scss';

const RelatedProducts: FC<ProductPageTypes.IRelatedProducts> = ({
  limit,
  relatedProducts,
  title,
  ariaAllProducts,
  linkAllProducts,
  isFamily,
  relatedProductsLinks,
  hideVariant,
}) => {
  const relatedProductsArr = relatedProducts?.nodes?.length
    ? sortRelatedProducts(relatedProductsLinks, relatedProducts.nodes)
    : [];

  return relatedProductsArr.length > 0 ? (
    <div className="related-products" data-testid="related-products">
      <Container fluid>
        <div className="related-products__wrap">
          <div className="related-products__heading">
            {title ? (
              <DangerouslySetInnerHtml
                className="related-products__title"
                element="h2"
                html={title}
              />
            ) : null}
          </div>

          <ControlledCarousel
            dotsOutside
            showDots
            showNavigators
            classes="product-carousel"
            responsiveObj={responsiveObj}
          >
            {relatedProductsArr
              .slice(0, limit || relatedProductsArr.length)
              .map((product, index) => {
                const id = `${product.cardTitle}_${index}`;

                return <ProductCard key={id} {...{ ...product, isFamily, hideVariant }} />;
              })}
          </ControlledCarousel>
        </div>
        {linkAllProducts.length ? (
          <div className="related-products__more-line">
            <Button
              ariaLabel={ariaAllProducts}
              classes="related-products__more"
              to={linkAllProducts[0].url}
              variant="secondary"
            >
              {linkAllProducts[0].name}
              <IconCustom icon="chevron-right" />
            </Button>
          </div>
        ) : null}
      </Container>
    </div>
  ) : null;
};
export default RelatedProducts;
