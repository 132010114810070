import React, { useCallback, FC } from 'react';
import { navigate, useLocation } from '@reach/router';

import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import { IGoBackButtonProps } from './model';
import './GoBackButton.scss';

const GoBackButton: FC<IGoBackButtonProps> = ({ ariaBack, text, staticUrl = '/' }) => {
  const location = useLocation();
  const handelClick = useCallback(() => {
    location && location.state
      ? navigate(-1)
      : window && (window.location.pathname = `/${staticUrl}/`);
  }, []);

  return (
    <Button
      classes="go-back-button"
      variant="link"
      clickHandler={handelClick}
      data-testid="go-back-button"
      ariaLabel={ariaBack}
    >
      <IconCustom icon="chevron-left" />
      <span className="go-back-button__text">{text}</span>
    </Button>
  );
};
export default GoBackButton;
