import React, { FC } from 'react';

import GatsbyImage from 'components/common/GatsbyImage';

import { IPageBreakerProps } from './model';
import './PageBreaker.scss';

const PageBreaker: FC<IPageBreakerProps> = ({ img, alt }) => (
  <div className="page-breaker">
    {img?.fallbackUrl ? <GatsbyImage useFocalPoint fluid={img} alt={alt} /> : null}
  </div>
);
export default PageBreaker;
