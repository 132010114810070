import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { parseBoolean } from 'utils/parseHelpers';
import { ArticleTeaserProps } from './model';
import './ArticleTeaser.scss';

const ArticleTeaser: FC<ArticleTeaserProps> = ({ tags = [], title, subtitle, text }) => {
  const category = tags?.find((tag) => parseBoolean(tag.articleCategory));

  return (
    <div className="article-teaser" data-testid="article-teaser">
      {category ? (
        <p className="article-teaser__category" data-testid="article-teaser__category">
          {category?.link?.[0]?.name}
        </p>
      ) : null}
      <h1 data-testid="article-teaser__title" className="article-teaser__title">
        <DangerouslySetInnerHtml html={title} element="span" />
        {subtitle ? (
          <span className="article-teaser__subtitle" data-testid="article-teaser__subtitle">
            {subtitle}
          </span>
        ) : null}
      </h1>
      {text ? (
        <DangerouslySetInnerHtml
          data-testid="article-teaser__text"
          className="article-teaser__text"
          html={text}
        />
      ) : null}
    </div>
  );
};

export default ArticleTeaser;
